import {
    AbiRegistry,
    Address,
    SmartContract,
} from '@multiversx/sdk-core/out';

// import * as json from './../../xflag.abi.json';
import { contractAddress } from '../../config';
const json = require('./../../xflag.abi.json');

const abiRegistry = AbiRegistry.create(json);

export const smartContract = new SmartContract({
    address: new Address(contractAddress),
    abi: abiRegistry
});
