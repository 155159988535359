import "./index.scss";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Terms from "./components/Footer/Terms/Terms";
import Policy from "./components/Footer/Policy/Policy";
import FAQ from "./components/Footer/FAQ/FAQ";
import ErrorPage from "./components/ErrorPage/ErrorPage";

const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);
root.render(
  <BrowserRouter>
      <Routes>
          <Route path="/" element={<App />} />
          <Route path="/flag" element={<App />} />
          <Route path="/home" element={<App />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/*" element={<ErrorPage />} />
      </Routes>

  </BrowserRouter>
);
