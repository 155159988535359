import React from 'react';
import "./FAQ.scss";
import { Link } from "react-router-dom";
import { routeNames } from "../../../routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

const FAQ: React.FC = () => {
    return (
        <div className="faq">
            <Link to={routeNames.home} className="home-icon">
                <FontAwesomeIcon icon={faHome} />
            </Link>

            <h1>Frequently Asked Questions (FAQ)</h1>

            <h3>1. What is X-Flag?</h3>
            <p>X-Flag is an NFT gallery website where each square represents a space you can buy to display your
                NFT.</p>

            <h3>2. How does it work?</h3>
            <p>To display your NFT on our grid, you can purchase a square. Each square holds only one NFT, and
                you can buy multiple squares to display multiple NFTs from your collection.</p>

            <h3>3. Is creating an account necessary to access the X-Flag gallery?</h3>
            <p>No, an account creation process isn't required. You can only log in using any method supported by the MultiverseX ecosystem, including the web wallet, MultiverseX mobile app, browser extension, Ledger.</p>

            <h3>4. What kind of NFTs can be displayed on the X-Flag gallery? </h3>
            <p>The X-Flag gallery is exclusively designed for NFTs existing within the MultiverseX ecosystem. Only NFTs
                on their network can be displayed here.</p>

            <h3>5. What currency is accepted for transactions within the X-Flag gallery?</h3>
            <p>Transactions on our website are facilitated using the MultiverseX token as the primary currency.</p>

            <h3>6. Can I display the same NFT on multiple squares?</h3>
            <p>No, each NFT can only be displayed on one square. However, you can display multiple different. </p>

            <h3>7. What happens when a square is already bought?</h3>
            <p>With each square purchased, the price for a new square increases. You can see the current price
                for a square in the top navigation bar.</p>
        </div>
    );
};

export default FAQ;
