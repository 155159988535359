import React from "react";
import "./Footer.scss";
import {Link} from 'react-router-dom';
import {routeNames} from "../../routes";

export const Footer = () => {
    return (
        <div className="footer">
            <div className="disclaimer">
                xFlag Homepage © 2024. All rights reserved.
                <div>
                    xFlag is not responsible for
                    the content of external sites. Images featured on homepage are © of
                    their respective owners.
                </div>

            </div>
            <span className="links">
                <Link to={routeNames.terms}>Terms of Service</Link>
                <Link to={routeNames.faq}>FAQ</Link>
                <Link to={routeNames.policy}>Policy</Link>
             </span>
        </div>
    );
};
