import BigNumber from 'bignumber.js';

export class CurrentPrice {
  price!: number;

  constructor(price: BigNumber) {
    if(!price) {
      return;
    }
    this.price = this.getPrice(price.toNumber());
  }

  getPrice(amount: number) {
    return amount / Math.pow(10, 18);
  }
}