import { RouteType } from '@multiversx/sdk-dapp/types';
import App from './components/App';
import { withPageTitle } from './components/PageTitle';
import { dAppName } from './config';
import Terms from "./components/Footer/Terms/Terms";
import Policy from "./components/Footer/Policy/Policy";
import FAQ from "./components/Footer/FAQ/FAQ";
import ErrorPage from "./components/ErrorPage/ErrorPage";

export const routeNames = {
  flag: '/flag',
  login: '/login',
  policy: '/policy',
  terms: '/terms',
  faq: '/faq',
  home: '/',
};

interface RouteWithTitleType extends RouteType {
  title: string;
}

export const routes: RouteWithTitleType[] = [
  {
    path: routeNames.home,
    title: 'flag',
    component: App
  },
  {
    path: routeNames.flag,
    title: 'flag',
    component: App
  },
  {
    path: routeNames.policy,
    title: 'Policy',
    component: Policy
  },
  {
    path: routeNames.terms,
    title: 'Terms of Service',
    component: Terms
  },
  {
    path: routeNames.faq,
    title: 'FAQ',
    component: FAQ
  },
  // Error route
  {
    path: '*',
    title: 'Error',
    component: ErrorPage
  },
];

export const mappedRoutes = routes.map((route) => {
  const title = route.title ? `${route.title} • Elrond ${dAppName}` : `Elrond ${dAppName}`;

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth,
  };
});


