import { useEffect, useState } from 'react';
import { UseGetPendingTransactionsReturnType } from '@multiversx/sdk-dapp/hooks';
import { removeAllSignedTransactions } from '@multiversx/sdk-dapp/services';
import axios, { AxiosResponse } from 'axios';

export const usePendingTX = (pendingTx: UseGetPendingTransactionsReturnType) => {
    const [txStatus, setNFT] = useState<string>('0');
    const [showSnackbar, setShowSnackbar] = useState(false);

    const pendingTX = async () => {
        try {
            if (pendingTx.hasPendingTransactions) {
                const tx = pendingTx.pendingTransactions[Object.keys(pendingTx.pendingTransactions)[0]].transactions[0];

                const xinterval = setInterval(async () => {
                    const multiversxDomain: string =
                        process.env.REACT_APP_MULTIVERX_ENVIRONMENT === "prod" ? "api" : "devnet-api";
                    let url = `https://${multiversxDomain}.multiversx.com/transactions/${tx.hash}`;

                    let response: AxiosResponse = await axios.get(url, {
                        headers: {
                            "Content-Type": "application/json",
                        }
                    });
                    if (response) {
                        console.error(response.data.status);
                        if (response.data.status === 'success') {
                            window.location.href = '/';
                            removeAllSignedTransactions();

                            setShowSnackbar(true);
                            setNFT('success');
                            clearInterval(xinterval);
                        } else if (response.data.status !== 'pending') {
                            setShowSnackbar(true);
                            setNFT('error');
                        }
                    }
                }, 2500)
            }
        } catch (err) {
            console.error('Unable to call NftMatrix', err);
        }
    };

    useEffect(() => {
        pendingTX();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        txStatus,
        showSnackbar,
        setShowSnackbar
    };
};
