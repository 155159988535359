import { useEffect, useState } from 'react';
import { ContractFunction, ResultsParser } from '@multiversx/sdk-core/out';
import { smartContract } from './smartContract';
import { CurrentPrice } from '../../models/currentPrice';
import axios, { AxiosResponse } from 'axios';
const resultsParser = new ResultsParser();

export const useCurrentPrice = () => {
    const [currentPrice, setCurrentPrice] = useState<CurrentPrice>();


    const getCurrentPrice = async () => {
        try {
            const query = smartContract.createQuery({
                func: new ContractFunction('currentPrice')
            });
            const multiversxDomain: string =
                process.env.REACT_APP_MULTIVERX_ENVIRONMENT === "prod" ? "api" : "devnet-api";
            let url = `https://${multiversxDomain}.multiversx.com/query`;

            let data = {
                scAddress: query.address.bech32(),
                caller: query.caller?.bech32() ? query.caller.bech32() : undefined,
                funcName: query.func.toString(),
                value: query.value ? query.value.toString() : undefined,
                args: query.getEncodedArguments()
            };
            let response: AxiosResponse = await axios.post(url, data, {
                headers: {
                    "Content-Type": "application/json",
                }
            });
            let queryResponse = {
                returnCode: response.data.returnCode,
                returnMessage: response.data.returnMessage,
                getReturnDataParts: () => response.data.returnData.map((item: any) => Buffer.from(item || "", "base64")),
            };
            const endpointDefinition = smartContract.getEndpoint('currentPrice');
            const { firstValue: price } = resultsParser.parseQueryResponse(
                queryResponse,
                endpointDefinition
            );

            const cPrice = new CurrentPrice(price?.valueOf());
            setCurrentPrice(cPrice);
        } catch (err) {
            console.error('Unable to call currentPrice', err);
        }
    };

    useEffect(() => {
        getCurrentPrice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return currentPrice;
};
