import { Button, Snackbar, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import "./nftCard.scss";

interface Props {
  nft?: {
    imageUrl: string;
    identifier: string;
    name: string;
    owner: string;
    collection: string;
    gridPosition: number;
    isNsfw: boolean;
  };
  onViewButtonClick: () => void;
}

function NftCard({ nft, onViewButtonClick }: Props) {
  const [showCopyMessage, setShowCopyMessage] = useState(false);

  const shortenOwner = (owner: string) => {
    const startPart = owner.substr(0, 7); // Get first 7 characters
    const endPart = owner.substr(owner.length - 6); // Get last 6 characters
    return `${startPart}.................${endPart}`;
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(nft!.owner);
    setShowCopyMessage(true);
  };

  const handleCloseCopyMessage = () => {
    setShowCopyMessage(false);
  };

  return (
    <div className="nft-card-backdrop">
      <div className="nft-card">
        <div className="nft-card__image-container">
          <img src={nft!.imageUrl} alt={nft!.name} className="nft-card__image" />
        </div>
        <div className="nft-card__details">
          <b>Name:</b>
          <span>&nbsp;</span>
          {nft!.name}
        </div>
        <div className="nft-card__details">
          <b>Collection:</b>
          <span>&nbsp;</span>
          {nft!.collection}
        </div>
        <div className="nft-card__details">
          <b>GridIndex:</b>
          <span>&nbsp;</span>
          {nft!.gridPosition}
        </div>
        <div className="nft-card__details">
          <b>Owner:</b>
          <span>&nbsp;</span>
          {shortenOwner(nft!.owner)}
          <span>&nbsp;&nbsp;</span>
          <Tooltip title="Copy to clipboard">
            <FontAwesomeIcon
              icon={faClipboard}
              className="nft-card__copy-icon"
              onClick={copyToClipboard}
            />
          </Tooltip>
        </div>

        <div className="nft-card__actions">
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              onViewButtonClick();
            }}
          >
            VIEW
          </Button>
        </div>

        <Snackbar
          open={showCopyMessage}
          autoHideDuration={3000} // Duration to display the message
          onClose={handleCloseCopyMessage}
          message="Owner address copied to clipboard"
        />
      </div>
    </div>
  );
}

export default NftCard;
