/* eslint-disable no-sequences */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import "./App.scss";
import SocialMedia from "./SocialMedia/SocialMedia";
import React, { useEffect, useState } from "react";
import { AccountType, EnvironmentsEnum } from "@multiversx/sdk-dapp/types";
import {
  TransactionsToastList,
  SignTransactionsModals,
  NotificationModal,
} from "@multiversx/sdk-dapp/UI";
import {
  DappProvider,
  AxiosInterceptorContext, // using this is optional
} from "@multiversx/sdk-dapp/wrappers";
import {
  apiTimeout,
  walletConnectV2ProjectId,
  sampleAuthenticatedDomains,
} from "./../config";
import { Login } from "./Login";
import { FlagGrid } from "./FlagGrid/FlagGrid";
import {
  useGetIsLoggedIn,
  useGetAccount,
  useGetPendingTransactions,
} from "@multiversx/sdk-dapp/hooks";
import { useNFTMatrix } from "./helpers/useNFTMatrix";
import { NFTEntry } from "../models/nftMatrixEntry.model";
import { useCurrentPrice } from "./helpers/useCurrentPrice";
import { CurrentPrice } from "../models/currentPrice";
import { usePendingTX } from "./helpers/getPendingTX";
import { Footer } from './Footer/Footer';


export default function App() {
  const isLoggedIn = useGetIsLoggedIn();
  const user: AccountType = useGetAccount();
  //BUG NFT ENTRYIES AR COMMING AFTER THE COMPONENTS ARE INITIALIZED
  const pendingTx = useGetPendingTransactions();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const usePendingTx = usePendingTX(pendingTx);
  const nftEntries: NFTEntry[] | undefined = useNFTMatrix();
  const currentPrice: CurrentPrice | undefined = useCurrentPrice();

  const isLandscape = () =>
      window.matchMedia("(orientation:landscape)").matches,
    [orientation, setOrientation] = useState(
      isLandscape() ? "landscape" : "portrait"
    ),
    onWindowResize = () => {
      clearTimeout((window as any).resizeLag);
      (window as any).resizeLag = setTimeout(() => {
        delete (window as any).resizeLag;
        setOrientation(isLandscape() ? "landscape" : "portrait");
      }, 200);
    };

  useEffect(
    () => (
      onWindowResize(),
      window.addEventListener("resize", onWindowResize),
      () => window.removeEventListener("resize", onWindowResize)
    ),
    []
  );
  return (
    <div className="App">
           <AxiosInterceptorContext.Provider>
        <AxiosInterceptorContext.Interceptor
          authenticatedDomains={sampleAuthenticatedDomains}
        >
          <DappProvider
            environment={
              process.env.REACT_APP_MULTIVERX_ENVIRONMENT === "prod"
                ? EnvironmentsEnum.mainnet
                : EnvironmentsEnum.devnet
            }
            customNetworkConfig={{
              name: "customConfig",
              apiTimeout,
              walletConnectV2ProjectId,
            }}
          >
            <div className="wrapper">
              {orientation === "landscape" ? (
                <>
                  <div className="big-wrapper">
                    <AxiosInterceptorContext.Listener />
                    <TransactionsToastList />
                    <NotificationModal />
                    <SignTransactionsModals className="custom-class-for-modals" />
                    {/* <small>You are running this application in <b>{process.env.NODE_ENV}</b> mode.</small> <small>Secret<b>{process.env.REACT_APP_SECRET_CODE}</b> </small> dev code */}
                    <SocialMedia />
                    <FlagGrid
                      isLoggedIn={isLoggedIn}
                      user={user}
                      nftEntries={nftEntries}
                      currentPrice={currentPrice}
                      pendingTx={pendingTx.hasPendingTransactions}
                    />

                    {user && user.address && (
                      <div className="user-container">
                        <div className="connected-label">Connected:</div>
                        <div className="buble">
                          ... {user.address.substr(user.address.length - 5)}
                        </div>
                      </div>
                    )}
                    {currentPrice && currentPrice.price && (
                      <div className="square-price-container">
                        <svg
                          width="28"
                          height="28"
                          viewBox="0 0 300 300"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="300"
                            height="300"
                            rx="150"
                            fill="black"
                            id="egld-token"
                          ></rect>
                          <path
                            d="M158.482 149.928L228.714 112.529L216.919 90L152.575 115.854C150.923 116.523 149.077 116.523 147.425 115.854L83.0814 90L71.25 112.602L141.482 150L71.25 187.398L83.0814 210L147.425 183.948C149.077 183.279 150.923 183.279 152.575 183.948L216.919 209.874L228.75 187.272L158.482 149.928Z"
                            fill="#23F7DD"
                          ></path>
                        </svg>
                        <div className="price">
                          {currentPrice!.price} per <div className="box"></div>
                        </div>
                      </div>
                    )}
                    <Login page="homepage" />
                  </div>
                </>
              ) : (
                <>
                  <Login page="homepage" />

                  <SocialMedia />
                  <div className="big-wrapper" style={{ padding: "40px" }}>
                    <h2>Switch orientation to landescape !</h2>
                  </div>
                </>
              )}
              <Footer />
            </div>
          </DappProvider>
        </AxiosInterceptorContext.Interceptor>
      </AxiosInterceptorContext.Provider>


    </div>
  );
}

// https://xflag-28fb2-default-rtdb.europe-west1.firebasedatabase.app/ DB CONNECTION
