// export const contractAddress =
//   'erd1qqqqqqqqqqqqqpgq72l6vl07fkn3alyfq753mcy4nakm0l72396qkcud5x';

// export const contractAddress =
//   "erd1qqqqqqqqqqqqqpgqfv96esn60jrqkrzkxyg7jchr5tyvpmrswk5s9czgh9"; DEv pret dinamic
export const contractAddress =
  process.env.REACT_APP_MULTIVERX_ENVIRONMENT === "prod"
    ? "erd1qqqqqqqqqqqqqpgqfv96esn60jrqkrzkxyg7jchr5tyvpmrswk5s9czgh9" //dev pret fix
    : "erd1qqqqqqqqqqqqqpgqag8pndtxqz4arhvqfk2tt45e4tsfk27uwk5s5ecyu9"; // sc prod
// "erd1qqqqqqqqqqqqqpgqfv96esn60jrqkrzkxyg7jchr5tyvpmrswk5s9czgh9"; //dev pret Miscator

export const dAppName = "Elrond X-Flag";

// Generate your own WalletConnect 2 ProjectId here: https://cloud.walletconnect.com/app
export const walletConnectV2ProjectId = "a395e74cb3334fcd723644df86c19eb8";

export const apiTimeout = 6000;
export const transactionSize = 15;
export const TOOLS_API_URL = "https://tools.elrond.com";
/**
 * Calls to these domains will use `nativeAuth` Baerer token
 */
export const sampleAuthenticatedDomains = [TOOLS_API_URL];
