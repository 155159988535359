import React from "react";
import "./SocialMedia.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faThreads,
} from "@fortawesome/free-brands-svg-icons";

function SocialMedia() {
  return (
    <div className="social-container">
      <a href="https://twitter.com/xflagproject" className="twitter social">
        <FontAwesomeIcon icon={faTwitter} size="2x" />
      </a>
      <a href="https://twitter.com/xflagproject" className="threads social">
        <FontAwesomeIcon icon={faThreads} size="2x" />
      </a>
      {/* <a
        href="https://facebook.com/isengard.market"
        className="facebook social"
      >
        <FontAwesomeIcon icon={faFacebook} size="2x" />
      </a> */}
      {/* <a href="https://discord.gg/3nVeWbmtsx" className="discord social">
        <FontAwesomeIcon icon={faDiscord} size="2x" />
      </a> */}
      {/* <a href="https://isengard.market/home" className="website social">
        <FontAwesomeIcon icon={faFirefoxBrowser} size="2x" />
      </a> */}
    </div>
  );
}

export default SocialMedia;
