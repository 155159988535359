import { useEffect, useState } from 'react';
import { ContractFunction, ResultsParser } from '@multiversx/sdk-core/out';
import { smartContract } from './smartContract';
import { NFTEntry } from '../../models/nftMatrixEntry.model';
import axios, { AxiosResponse } from 'axios';

const resultsParser = new ResultsParser();

export const useNFTMatrix = () => {
    const [nftMatrix, setNFTMatrixEntries] = useState<NFTEntry[]>();


    const NFTMatrixEntries = async () => {
        try {
            const query = smartContract.createQuery({
                func: new ContractFunction('nftMatrix')
            });
            const multiversxDomain: string =
                process.env.REACT_APP_MULTIVERX_ENVIRONMENT === "prod" ? "api" : "devnet-api";
            let url = `https://${multiversxDomain}.multiversx.com/query`;

            let data = {
                scAddress: query.address.bech32(),
                caller: query.caller?.bech32() ? query.caller.bech32() : undefined,
                funcName: query.func.toString(),
                value: query.value ? query.value.toString() : undefined,
                args: query.getEncodedArguments()
            };
            let response: AxiosResponse = await axios.post(url, data, {
                headers: {
                    "Content-Type": "application/json",
                }
            });
            let queryResponse = {
                returnCode: response.data.returnCode,
                returnMessage: response.data.returnMessage,
                getReturnDataParts: () => {
                    if (!response.data.returnData) {
                        return;
                    }
                    return response.data.returnData.map((item: any) => Buffer.from(item || "", "base64"))
                }
            };
            const endpointDefinition = await smartContract.getEndpoint('nftMatrix');
            const { firstValue: entries } = await resultsParser.parseQueryResponse(
                queryResponse,
                endpointDefinition
            );
            const entriesJMK = await entries?.valueOf().map((e: any) => new NFTEntry(e));
            // console.warn(entriesJMK);
            setNFTMatrixEntries(entriesJMK);
        } catch (err) {
            console.error('Unable to call NftMatrix', err);
        }
    };

    useEffect(() => {
        NFTMatrixEntries();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return nftMatrix;
};
