/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import React from "react";
import "./FlagGrid.scss";
import { Login } from "../Login/index";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import axios from "axios";
import { NFT } from "../../models/nft.model";
import NftCard from "../nftCard/nftCard";
import { AccountType } from "@multiversx/sdk-dapp/types";
import { NFTEntry } from "../../models/nftMatrixEntry.model";
import { CurrentPrice } from "../../models/currentPrice";
import { contractAddress } from "../../config";
import { sendTransactions } from "@multiversx/sdk-dapp/services";
import {
  Address,
  BytesValue,
  ContractCallPayloadBuilder,
  ContractFunction,
  Transaction,
  U64Value,
} from "@multiversx/sdk-core/out";
import BigNumber from "bignumber.js";
interface State {
  hoverIndex: number | null;
  zoomlevel2: number;
  dialogOpen: boolean;
  flagActionsDisabled: boolean;
  isOpen: boolean;
  logined: boolean;
  selectedNFT?: NFT;
  name: string;
  owner: string;
  url: string;
  identifier: string;
}

interface Props {
  isLoggedIn: boolean;
  user: AccountType;
  nftEntries: NFTEntry[] | undefined;
  currentPrice: CurrentPrice | undefined;
  pendingTx: boolean | undefined;
  onViewButtonClick?: () => void;
}

export const EGLDNOMINATION = 1000000000000000000;
export const EGLDNOMINATIONSTR = "0000000000000";

export class FlagGrid extends React.Component<Props, State> {
  multiversxDomain: string =
    process.env.REACT_APP_MULTIVERX_ENVIRONMENT === "prod"
      ? "api"
      : "devnet-api";

  state = {
    hoverIndex: null,
    zoomlevel2: 100,
    dialogOpen: false,
    flagActionsDisabled: false,
    isOpen: false,
    logined: false,
    selectedNFT: undefined,
    name: "",
    owner: "",
    url: "",
    identifier: "",
  };
  squareFindIndex: number = 0;
  ownedSquareBasedOnGridIndex: number = -1;
  ownedSquares: {
    gridPosition: number;
    imageUrl: string;
    identifier: string;
    name: string;
    owner: string;
    collection: string;
    isNsfw: boolean;
  }[] = [];
  nfts: NFT[] = [];
  gridIndex = 0;
  currentPrice: CurrentPrice | undefined;
  selectedNFT!: NFT;
  onViewButtonClick: () => void;

  constructor(props: Props) {
    super(props);
    this.onViewButtonClick = () => {};
    this.onScroll = this.onScroll.bind(this);
    this.handleClickToOpen = this.handleClickToOpen.bind(this);
    this.handleToClose = this.handleToClose.bind(this);
    this.openNftDialog = this.openNftDialog.bind(this);
    this.closeNftDialog = this.closeNftDialog.bind(this);
    this.buySquare = this.buySquare.bind(this);

    if (this.props.isLoggedIn) {
      this.getUserNFTS(this.props.user.address);
    }
    const timer = setTimeout(() => {
      if (this.props.nftEntries === undefined) {
        this.render();
      } else {
        this.props.nftEntries?.forEach((nft) => {
          this.getNFTByIdentifier(nft);
        });
        this.render();

        clearTimeout(timer);
      }
    }, 1000);
  }

  handleHover = (index: any) => {
    this.setState({ hoverIndex: index });
  };

  getUserNFTS(id: string) {
    axios
      .get(
        `https://${this.multiversxDomain}.multiversx.com/accounts/${id}/nfts?type=NonFungibleESDT&includeFlagged=false&excludeMetaESDT=true`
      )
      .then((response: { data: NFT[] }) => {
        this.nfts = response.data as NFT[];
      });
  }

  // downloadImageToBlob(nftMediaURL: string): any {
  //   axios.get(nftMediaURL).then(() => {
  //     // const imageData = response.data;
  //     // const blob = b64toBlob(imageData);
  //     // (response as any).arrayBuffer().then(async (buffer: any) => {
  //     //   const url = window.URL.createObjectURL(new Blob([buffer]));
  //     //   const imageBlob = await buffer.blob();
  //     //   console.log(url, imageBlob);
  //     // const link = document.createElement("a");
  //     // link.href = url;
  //     // link.setAttribute("download", "image.png"); //or any other extension
  //     // document.body.appendChild(link);
  //     // link.click();
  //     // });
  //   });
  // }
  getNFTByIdentifier(nft: NFTEntry): void {
    const apiUrl = `https://${this.multiversxDomain}.multiversx.com/nfts/${nft.collection}`;
    axios
      .get(apiUrl)
      .then((response) => {
        console.warn(response.data);
        const { media, identifier, name, owner, collection, isNsfw } =
          response.data;
        const gridPosition = (nft as any).position;

        this.ownedSquares.push({
          gridPosition,
          imageUrl: media[0].url,
          identifier,
          name,
          owner,
          collection,
          isNsfw,
        });

        // this.downloadImageToBlob(media[0].url);
      })
      .catch((err) => {
        console.error("Error fetching NFT:", err);
      });
  }

  onScroll(event: any) {
    // console.log(this.state);
    var zoomlevel = this.state.zoomlevel2;
    if (event.deltaY < 0) {
      this.setState({
        zoomlevel2: (zoomlevel += zoomlevel * 0.05),
      });
      console.log("scroll UP");
    }
    // If the user scrolls down, decrease the zoom level
    else if (zoomlevel > 60 && event.deltaY > 0) {
      console.log("scroll down");
      this.setState({
        zoomlevel2: (zoomlevel -= zoomlevel * 0.05),
      });
    }
  }

  handleClickToOpen = (gridIndex: number) => {
    if (this.props.pendingTx) {
      return;
    }

    if (this.state.dialogOpen || this.state.isOpen) {
      return;
    }
    // console.log(gridIndex);
    this.gridIndex = gridIndex;

    // change this logic to IF SQUARE HAS NFT BASICALLY
    this.ownedSquareBasedOnGridIndex = this.ownedSquares.findIndex(
      (os) => os.gridPosition === this.gridIndex
    );
    if (this.ownedSquareBasedOnGridIndex > -1) {
      this.openNftDialog();
      return;
    }

    this.setState({
      flagActionsDisabled: true,
      dialogOpen: true,
    });
  };

  nftClicked = (nft: NFT) => {
    this.setState({
      selectedNFT: nft,
    });
    this.render();
  };

  openNftDialog() {
    this.setState((prevState) => ({
      flagActionsDisabled: true,
      isOpen: true,
    }));
  }

  closeNftDialog() {
    this.setState((prevState) => ({
      flagActionsDisabled: false,
      isOpen: false,
    }));
  }

  handleToClose = () => {
    this.setState({
      flagActionsDisabled: false,
      dialogOpen: false,
    });
  };

  buySquare = async () => {
    if (!this.state.selectedNFT) {
      alert("Select an NFT to continue !");
      return;
    }
    let contractCallBuilder = new ContractCallPayloadBuilder().setFunction(
      new ContractFunction("XClaim")
    );
    contractCallBuilder.addArg(
      BytesValue.fromUTF8(this.state.selectedNFT!["identifier"])
    );
    contractCallBuilder.addArg(new U64Value(this.state.selectedNFT!["nonce"]));
    contractCallBuilder.addArg(new U64Value(new BigNumber(this.gridIndex)));
    let payload = contractCallBuilder.build();
    let price =
      this.props.currentPrice!.price!.toFixed(5).replace(".", "") +
      EGLDNOMINATIONSTR;
    if (price.charAt(0) === "0") {
      price = price.substring(1, price.length);
    }
    let tx = new Transaction({
      sender: new Address(this.props.user.address),
      data: payload,
      chainID: process.env.REACT_APP_MULTIVERX_ENVIRONMENT === "prod" ? "1" : "D",
      gasLimit: 30000000,
      receiver: new Address(contractAddress),
      value: +price,
    });

    const { sessionId, ceva } = await sendTransactions({
      transactions: tx,
      transactionsDisplayInfo: {
        processingMessage: "Processing X-Flag Square transaction",
        errorMessage: "An error has occurred during Buy X-Flag Square",
        successMessage: "Buy X-Flag Square transaction successful",
      },
      redirectAfterSign: true,
      callbackRoute: "home",
    });
    // const cv = async () => {
    //   if (tx && tx.getHash()) {
    //     let txOnNetwork = await this.provider.getTransaction(
    //       tx.getHash().toString()
    //     );
    //     var success = txOnNetwork.status.isSuccessful();
    //     if (success) {
    //       alert("You successfully bought SQUARE the nft for sale.");
    //       console.warn("alerte");
    //       this.handleToClose();
    //     }
    //   }
    // };
    // setTransactionSessionId(sessionId);
  };

  handleClickOutside: React.MouseEventHandler<HTMLDivElement> = (event) => {
    const target = event.target as Node;
    const dialogContent = document.querySelector(".MuiDialogContent-root");
    if (target instanceof Node) {
      if (dialogContent && !dialogContent.contains(target)) {
        this.closeNftDialog();
      }
    }
  };

  handleDocumentClick = (event: MouseEvent) => {
    const target = event.target as Node;
    const dialogContent = document.querySelector(".MuiDialogContent-root");
    if (target instanceof Node) {
      if (dialogContent && !dialogContent.contains(target)) {
        this.closeNftDialog();
      }
    }
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleDocumentClick);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleDocumentClick);
  }

  isImage(nft: NFT): boolean {
    if (!nft.media) {
      return false;
    }
    const fileType = nft.media[0].fileType;

    return (
      fileType === "image/jpeg" ||
      fileType === "image/jpg" ||
      fileType === "image/png" ||
      fileType === "image/bmp" ||
      fileType === "image/gif" ||
      fileType === "image/svg+xml" ||
      fileType === "image/webp"
    );
  }

  render(): any {
    var nftstemplate: JSX.Element[] = this.nfts.map((nft, i) => (
      <div className="nefete" key={i}>
        {this.isImage(nft) ? (
          <div
            className={`isengard-gradient-black ${
              this.state.selectedNFT &&
              this.state.selectedNFT["identifier"] === nft.identifier
                ? "isengard-gradient-black-selected"
                : ""
            }`}
            onClick={() => this.nftClicked(nft)}
          >
            <div className="inner-square">
              <img src={nft.url} width="150px" height="200px" alt={nft.name} />
            </div>
          </div>
        ) : (
          <div>
            <video
              muted
              autoPlay={true}
              loop={true}
              src={nft.url}
              width="150px"
              height="200px"
            ></video>
          </div>
        )}
        {nft.name}
      </div>
    ));

    const grid = [];

    for (let i = 0; i < 1050; i++) {
      this.squareFindIndex = this.ownedSquares.findIndex(
        (os) => os.gridPosition === i
      );
      grid.push(
        <div
          key={i}
          className="square"
          onMouseEnter={() => this.handleHover(i)}
          onMouseLeave={() => this.handleHover(null)}
          onClick={() => this.handleClickToOpen(i)}
        >
          {/* this can be optimized */}

          {this.squareFindIndex > -1 && (
            <div className="nft-with-photo">
              <img
                src={this.ownedSquares[this.squareFindIndex].imageUrl}
                width={35}
                height={35}
                alt=""
              />
            </div>
          )}
        </div>
      );
    }
    return (
      <>
        {/* https://prc5.github.io/react-zoom-pan-pinch/?path=/story/docs-props--page */}
        <TransformWrapper
          initialScale={0.75}
          centerOnInit={true}
          disabled={this.state.flagActionsDisabled}
          doubleClick={{ disabled: true }}
          wheel={{
            step: 0.05,
            disabled: this.state.flagActionsDisabled,
            touchPadDisabled: this.state.flagActionsDisabled,
          }}
          pinch={{
            disabled: this.state.flagActionsDisabled,
          }}
        >
          <React.Fragment>
            <TransformComponent>
              <div
                className="flag-wrapper"
                onWheelCapture={this.onScroll}
                style={{
                  zoom: `${this.state.zoomlevel2}%`,
                }}
              >
                <Dialog
                  fullWidth={true}
                  maxWidth={"md"}
                  open={this.state.dialogOpen}
                  onClose={this.handleToClose}
                >
                  <DialogContent>
                    {this.props.isLoggedIn ? (
                      <>
                        <div className="selected-square-container">
                          <div className="square-container">
                            <div className="content">
                              #<b>{this.gridIndex}</b>
                            </div>
                          </div>

                          <div className="square-price-container-v2">
                            <svg
                              width="28"
                              height="28"
                              viewBox="0 0 300 300"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="300"
                                height="300"
                                rx="150"
                                fill="black"
                                id="egld-token"
                              ></rect>
                              <path
                                d="M158.482 149.928L228.714 112.529L216.919 90L152.575 115.854C150.923 116.523 149.077 116.523 147.425 115.854L83.0814 90L71.25 112.602L141.482 150L71.25 187.398L83.0814 210L147.425 183.948C149.077 183.279 150.923 183.279 152.575 183.948L216.919 209.874L228.75 187.272L158.482 149.928Z"
                                fill="#23F7DD"
                              ></path>
                            </svg>
                            <div className="price">
                              {this.props.currentPrice?.price} per{" "}
                              <div className="box"></div>
                            </div>
                          </div>
                        </div>
                        <div className="square-container">
                          <div className="content">
                            {this.state.selectedNFT
                              ? "Selected  NFT - " +
                                this.state.selectedNFT["name"]
                              : "Select an NFT -"}
                          </div>
                        </div>
                        <div className="nft-list">
                          {nftstemplate}
                          <br />
                        </div>
                        <DialogActions>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={this.handleToClose}
                          >
                            Close
                          </Button>
                          <Button
                            color="primary"
                            variant="contained"
                            disabled={!this.state.selectedNFT}
                            onClick={this.buySquare}
                            className="button"
                          >
                            buy
                          </Button>
                        </DialogActions>
                      </>
                    ) : (
                      <>
                        <div>Square selected is: {this.gridIndex} </div>
                        <div>
                          You need to be Authenticated in order to buy a SQUARE
                        </div>
                        <DialogActions>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={this.handleToClose}
                          >
                            Close
                          </Button>
                          <Login page="otherpage"></Login>
                        </DialogActions>
                      </>
                    )}
                  </DialogContent>
                </Dialog>
                {/* <div className="squares-status">
                  0 / 1000 <hr />{" "}
                  <>
                    Current Price: {this.props.currentPrice?.price} EGLD per
                    square
                  </>
                </div> */}

                <div className="label">
                  <span className="flicker-2">X</span>-FLAG
                </div>
                <div className="grid triangle">{grid}</div>
                <div>
                  <Dialog
                    open={this.state.isOpen}
                    onClick={this.handleClickOutside}
                  >
                    <DialogContent>
                      <NftCard
                        nft={
                          this.ownedSquareBasedOnGridIndex > -1
                            ? this.ownedSquares[
                                this.ownedSquareBasedOnGridIndex
                              ]
                            : undefined
                        }
                        // onMakeAnOfferButtonClick={() => {
                        //   alert("Clicked on Make an offer button");
                        //   this.closeNftDialog();
                        // }}
                        onViewButtonClick={() => {
                          const baseUrl =
                            process.env.REACT_APP_MULTIVERX_ENVIRONMENT ===
                            "prod"
                              ? "https://xspotlight.com/nfts/"
                              : "https://devnet.xspotlight.com/nfts/";

                          const identifier =
                            this.ownedSquares[this.ownedSquareBasedOnGridIndex]
                              ?.identifier || "";
                          const url = `${baseUrl}${identifier}`;

                          window.open(url, "_blank");
                        }}
                        // alreadyBought={this.ownedSquareBasedOnGridIndex > -1}
                      />
                    </DialogContent>
                  </Dialog>
                  <div className="snackbar-container"></div>
                </div>
              </div>
            </TransformComponent>
          </React.Fragment>
        </TransformWrapper>
      </>
    );
  }
}
