import React from 'react';
import './ErrorPage.scss';

const ErrorPage: React.FC = () => {

    return (
        <div className="error-container">
            <h1 className="error-title">Ooops!</h1>
            <p className="error-message">Sorry, an error has occurred. The page you were looking for does not exist.</p>
            <p className="error-status">#Not Found</p>
        </div>
    );
};

export default ErrorPage;
