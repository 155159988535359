import {
  Button,
  Dialog,
  // DialogTitle,
  DialogContent,
  // DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { useGetIsLoggedIn, useGetAccount } from "@multiversx/sdk-dapp/hooks";
import { AccountType } from "@multiversx/sdk-dapp/types";
import {
  ExtensionLoginButton,
  WebWalletLoginButton,
  LedgerLoginButton,
  WalletConnectLoginButton,
} from "@multiversx/sdk-dapp/UI";
import React, { useState } from "react";
import { walletConnectV2ProjectId } from "../../config";
import { routeNames } from "../../routes";
import "./Login.scss";
import { logout } from "@multiversx/sdk-dapp/utils";

interface LoginProps {
  page: "homepage" | "otherpage";
}

export function Login(props: LoginProps) {
  const isLoggedIn = useGetIsLoggedIn();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const user: AccountType = useGetAccount();
  const LogOut = () => {
    logout(`${window.location.origin}/home`);
  };

  const commonProps = {
    callbackRoute: routeNames.flag,
  };
  const [open, setOpen] = useState(false);

  const handleClickToOpen = () => {
    setOpen(true);
  };

  const handleToClose = () => {
    setOpen(false);
    console.log("inchis");
  };

  return (
    <div className={`Login-${props.page}`}>
      <div>
        {isLoggedIn ? (
          <Button variant="contained" color="primary" onClick={LogOut}>
            LogOut
          </Button>
        ) : (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickToOpen}
            >
              Login
            </Button>
            <Dialog open={open} onClose={handleToClose}>
              <DialogContent>
                <div className="home d-flex flex-fill align-items-center">
                  <div className="m-auto" data-testid="unlockPage">
                    <div className="card my-4 text-center card-xflag">
                      <div className="card-body py-4 px-2 px-sm-2 mx-lg-4">
                        <h4 className="mb-4">Login</h4>

                        <ExtensionLoginButton
                          loginButtonText="Extension"
                          {...commonProps}
                        />

                        <WebWalletLoginButton
                          loginButtonText="Web wallet"
                          {...commonProps}
                        />
                        <LedgerLoginButton
                          loginButtonText="Ledger"
                          className="test-class_name"
                          {...commonProps}
                        />
                        <WalletConnectLoginButton
                          loginButtonText="MultiverseX"
                          {...commonProps}
                          {...(walletConnectV2ProjectId
                            ? {
                                isWalletConnectV2: true,
                              }
                            : {})}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleToClose}
                  color="primary"
                  variant="contained"
                  autoFocus
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </div>
    </div>
  );
}
